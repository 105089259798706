.menuContainer {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;

  .link {
    text-decoration: none;
  }

  .menuButton {
    display: flex;
    flex-direction: column;
    height: 160px;
    width: 210px;
    margin: 8px 0 0;
    align-items: center;
    justify-content: center;
    background-color: #333333;
    color: #FFFFFF;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
    border: none;
    outline: none;

    &.active {
      background-color: #ff5722;
    }

    .activeCount {
      position: absolute;
      margin-bottom: 100px;
      margin-left: 40px;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.6px #ffffff solid;
      background-color: rgba(13, 13, 13, 0.75);
      border-radius: 20px;
      font-size: 17px;
      line-height: 17px;
    }
    img {
      height: 50px;
    }

    .buttonText {
      font-size: 14px;
      margin-top: 10px;
      font-weight: 600;
    }
  }
}
