.mobileTrackPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .trackInfo {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    color: #FFFFFF;
    margin-left: 40px;
    font-weight: 700;

    font-size: 14px;
  }


  .durationInfo {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    font-weight: 700;
    color: #d0d0d0;
    text-align: left;
    justify-content: center;

    .timeElapsed {
      font-size: 39px;
      width: 110px;
    }

    .totalDuration {
      margin-left: 40px;
      font-size: 29px;
    }
  }

  .nextBtn {
    margin-left: auto;
    width: 25px;
    height: 25px;
    z-index: 10;
    margin-right: 20px;
    cursor: pointer;

    &.inactive {
      opacity: 0.5;
    }
  }
}
