.mobileHeader {
  flex: none;
  display: flex;
  width: 100%;
  height: 85px;
  background-color: #0D0D0D;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);

  img {
    width: 161px;
  }
}
