.contentContainer {
  width: 100%;
  min-width:0;
  padding: 0 27px 30px 45px;
}

.loadingContainer {
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
}
