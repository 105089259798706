.musicPlayer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 190px;
  background-color: #2c2c2c;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
  align-items: center;
  z-index: 2;

  .headerLogo {
    width: 400px;
    min-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
}
