.settingsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 4;
  pointer-events: none;

  .blur {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
  }

  .settings {
    width: 200px;
    height: 400px;
    background-color: #FFFFFF;
  }
}
