.profileContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;

  .profilePage {
    margin-top: 35px;
    text-align: center;
    z-index: 2;

    .spotifyPanel{
      width: calc(100% - 65px);
      border: solid 0.7px rgba(255, 255, 255, 0.79);
      border-radius: 4px;
      padding: 20px;

      .spotifyList {
        .spotifyItem {
          display: flex;
          flex-direction: row;
          margin: 10px;

          .spotifyImg {
            width: 150px;
            height: 150px;
            margin-right: 20px;

            img {
              width: 100%;
            }
          }

          .spotifyInfo {
            color: #ffffff;
            text-align: left;
          }
        }
      }
    }

    .footer {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 0;
      left: 0;
      height: 100px;
      width: 100%;
      background-color: #262626;
      color: #FFFFFF;

      .logoutBtn {
        margin-left: auto;
        display:flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 220px;
        height: 68px;
        background-color: #333333;
        color: #FFFFFF;
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
        font-size: 12pt;
        border: none;
        outline: none;
      }
    }
  }


  .loadingSpinner {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    .loadingText {
      color: #FFFFFF;
      font-size: 13px;
    }

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #333333;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
