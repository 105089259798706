.footerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 105px;
  background-color: #262626;
  color: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
  align-items: center;
  z-index: 5;


  .footerText {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    text-align: left;

    .title {
      font-size: 22px;
      font-weight: bold;
    }

    .copyright {
      margin: 5px 0;
      font-size: 15px;
    }
  }

  .footerActions {
    margin-left: auto;
    margin-right: 30px;
    display: flex;
    flex-direction: row;

    .footerBtn {
      display:flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      width: 220px;
      height: 68px;
      background-color: #333333;
      color: #FFFFFF;
      box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
      font-size: 12pt;
      border: none;
      outline: none;

      img {
        height: 20px;
      }
    }
  }
}
