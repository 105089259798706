.mobileTilesContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .backButton {
    margin: 25px 0 10px 25px;
    width: 30px;
    height: 30px;
  }

  .line {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .tileList {
    text-align: left;
    z-index: 2;

    .tile {
      color: #FFFFFF;
      display: flex;
      flex-direction: row;
      padding: 10px 25px;

      &.active {
        background-color: #ff5722;
      }

      .themeIcon {
        position: relative;
        min-width: 87px;
        min-height: 87px;
        width: 87px;
        height: 87px;

        .tileBackgroundTheme {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        .listCount {
          position: absolute;
          z-index: 15;
          right: 8px;
          top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border: 1px #ffffff solid;
          background-color: #0d0d0d;
          border-radius: 12.5px;
          font-size: 10px;
          line-height: 10px;
        }
        .tileImgTheme {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 10;
          min-width: 72px;
          min-height: 72px;
          max-width: 72px;
          max-height: 72px;

          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
        }
      }

      .playlistIcon {
        .tileImg {
          margin-top: 5px;
          min-width: 87px;
          min-height: 87px;
          max-width: 87px;
          max-height: 87px;

          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          user-drag: none;
        }
      }

      .tileInfo {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

        .upper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .tileFavorite {
            margin-left: auto;
            width: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #333333;

            img {
              width: 100%;
            }

            &.active {
              background-color: #ff5722;
            }
          }

          .tileTitle {
            font-size: 17px;
            font-weight: 500;
          }
        }

        .tileTagline {
          text-align: left;
          margin-top: 10px;
        }
      }


    }
  }

  .loadingSpinner {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    .loadingText {
      color: #FFFFFF;
      font-size: 13px;
      margin-top: 20px;
    }

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #333333;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .fadeOut {
    min-height: 100%;
    min-width: 35px;
    margin-left: -35px;
    z-index: 4;
    background: -webkit-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -moz-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -o-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
    background-image: -ms-linear-gradient(90deg,
                    rgba(32, 32, 32, 0) 0%,
                    rgba(32, 32, 32, 1) 100%
    );
  }
}
