.characterPicker {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: 80px;
  width: 100%;
  overflow-x: scroll;

  font-size: 14px;
  color: white;

  .character {
    padding: 10px;
  }

  .active {
    color: #FFFFFF;
  }

  .inactive {
    color: #777777;
  }
}
