.activeContainer {
  display: flex;
  height: 100%;
  flex-direction: column;

  .grid {
    margin-top: 35px;
    display: grid;
    grid-gap: 18px;
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    overflow-x: auto;
    text-align: center;
    z-index: 2;
    width: fit-content;

    .tile {
      width: 350px;
      height: fit-content;
      background-color: #333333;
      color: #FFFFFF;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: baseline;
      padding: 10px;

      &.active {
        background-color: #ff5722;
      }
      .tileRowUpper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .tileTitle {
          font-size: 17px;
          font-weight: 500;
        }

        .tileFavorite {
          margin-left: auto;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #333333;

          img {
            width: 100%;
          }

          &.active {
            background-color: #ff5722;
          }
        }
      }

      .tileRowLower {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        max-height: 155px;

        .tileImg {
          border-radius: 5px;
          min-width: 145px;
          min-height: 145px;
          max-width: 145px;
          max-height: 145px;
        }

        .tileInfo {
          text-align: left;
          margin-top: 10px;
          padding-left: 10px;
        }
      }

      .actionsBar {
        display: flex;
        width: 100%;
        height: 45px;
        background-color: #282828;
        margin-top: 10px;
        border-radius: 3px;
        align-items: center;

        .percentageControl {
          display: flex;
          margin-left: 30px;

          .percentageBtn {
            width: 30px;
            height: 30px;
          }

          .percentage {
            display: flex;
            background: #333333;
            width: 67px;
            height: 34px;
            margin: 0 10px;

            align-items: center;
            justify-content: center;

            font-weight: 700;
            border-radius: 7px;
          }
        }

        .removeBtn {
          margin-left: auto;
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .fadeOut {
    min-height: 100%;
    min-width: 35px;
    margin-left: -35px;
    z-index: 4;
    background: -webkit-linear-gradient(90deg,
            rgba(32, 32, 32, 0) 0%,
            rgba(32, 32, 32, 1) 100%
    );
    background-image: -moz-linear-gradient(90deg,
            rgba(32, 32, 32, 0) 0%,
            rgba(32, 32, 32, 1) 100%
    );
    background-image: -o-linear-gradient(90deg,
            rgba(32, 32, 32, 0) 0%,
            rgba(32, 32, 32, 1) 100%
    );
    background-image: linear-gradient(90deg,
            rgba(32, 32, 32, 0) 0%,
            rgba(32, 32, 32, 1) 100%
    );
    background-image: -ms-linear-gradient(90deg,
            rgba(32, 32, 32, 0) 0%,
            rgba(32, 32, 32, 1) 100%
    );
  }

  .loadingSpinner {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;

    .loadingText {
      color: #FFFFFF;
      font-size: 13px;
    }

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #333333;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
