.mobileContentContainer {
  width: 100%;
  min-width:0;
  flex: auto;
  overflow-y: auto;
  background-color: #202020;
}

.mobileLoadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
}
