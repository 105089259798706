.trackPlayer {
  width: 580px;
  height: 165px;
  background-color: #0d0d0d;
  color: #FFFFFF;
  box-shadow: inset 0px 0px 5px 1px rgba(0,0,0,0.75);
  border-radius: 15px;
  margin: 0 45px;
  display: flex;
  flex-direction: column;


  .durationInfo {
    margin: 20px 29px 0px 29px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    font-weight: 700;
    text-align: left;

    .timeElapsed {
      font-size: 39px;
      width: 110px;
    }

    .totalDuration {
      margin-left: 40px;
      font-size: 29px;
    }

    .removeBtn {
      margin-left: auto;
      width: 30px;
      height: 30px;
      z-index: 10;
      cursor: pointer;

      &.inactive {
        opacity: 0.5;
      }
    }
  }
  .trackInfo {
    display: flex;
    flex-direction: row;
    margin: auto 29px 20px 29px;
    font-weight: 600;
    align-items: flex-end;

    .song {
      text-align: left;
      .title {

      }

      .artist {

      }
    }

    .general {
      margin-left: auto;
      text-align: right;

      .volume {
        display: flex;

        img {
          width: 18px;
          margin-right: 10px;
        }
      }
    }
  }
}
